import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {
    ROUTE_BILLING,
    ROUTE_COMPANY,
    ROUTE_DASHBOARD,
    ROUTE_DRIVER,
    ROUTE_HOME,
    ROUTE_INVITE,
    ROUTE_LOGIN,
    ROUTE_MANAGER_MANAGEMENT, ROUTE_NOTIFICATIONS, ROUTE_PROMOTERS,
    ROUTE_RIDE,
    ROUTE_SHARE_MANAGEMENT, ROUTE_STATISTICS,
    ROUTE_TAXI,
    ROUTE_TRANSACTIONS
} from '@/router/routes';
import { authStoreGetter } from '@/store/auth.store';
import User from '@/models/User';
import { UserRoles } from '@/enum/UserRoles';

Vue.use(VueRouter);

// Handle NavigationDuplicated stack error:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    // @ts-ignore
    return originalPush.call(this, location).catch((err) => err);
};

/**
 * checks if the user is allowed to visit the super admin views, if not, redirects the
 * user to a certain part of the application
 * @param to
 * @param from
 * @param next
 */
const redirectOnForbidden = async (to: any, from: any, next: any) => {
    const user: User = store.getters[`auth/${authStoreGetter.CURRENT_USER}`];
    if (user.role === UserRoles.ADMIN) {
        await next();
    } else if (user.role === UserRoles.MANAGER) {
        await next({ name: ROUTE_COMPANY });
    } else {
        await next({ name: ROUTE_DASHBOARD });
    }
};

const routes = [
    {
        path: '/login',
        name: ROUTE_LOGIN,
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.view.vue')
    },
    {
        path: '/invite',
        name: ROUTE_INVITE,
        component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.view.vue')
    },
    {
        path: '/',
        name: ROUTE_HOME,
        beforeEnter: async (to: any, from: any, next: any) => {
            // Check if token is set. If not, redirect to login
            if (store.getters[`auth/${authStoreGetter.TOKEN}`]) {
                await next();
            } else {
                await next({ name: ROUTE_LOGIN });
            }
        },
        redirect: { name: ROUTE_DASHBOARD },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.view.vue'),
        children: [
            {
                path: '/dashboard',
                name: ROUTE_DASHBOARD,
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.view.vue')
            },
            {
                path: '/drivers',
                name: ROUTE_DRIVER,
                component: () => import(/* webpackChunkName: "drivers" */ '../views/Drivers.view.vue')
            },
            {
                path: '/taxis',
                name: ROUTE_TAXI,
                component: () => import(/* webpackChunkName: "taxis" */ '../views/Taxis.view.vue')
            }, {
                path: '/rides',
                name: ROUTE_RIDE,
                component: () => import(/* webpackChunkName: "rides" */ '../views/Rides.view.vue')
            },
            {
                path: '/statistics',
                name: ROUTE_STATISTICS,
                component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.view.vue')
            },
            {
                path: '/promoters',
                beforeEnter: redirectOnForbidden,
                name: ROUTE_PROMOTERS,
                component: () => import(/* webpackChunkName: "promoters" */ '../views/Promoter.view.vue')
            },
            {
                path: '/managers',
                beforeEnter: redirectOnForbidden,
                name: ROUTE_MANAGER_MANAGEMENT,
                component: () => import(/* webpackChunkName: "managers" */ '../views/Managers.view.vue')
            },
            {
                path: '/share-management',
                beforeEnter: redirectOnForbidden,
                name: ROUTE_SHARE_MANAGEMENT,
                component: () => import(/* webpackChunkName: "share-management" */ '../views/ShareManagement.view.vue')
            },
            {
                path: '/transactions',
                beforeEnter: redirectOnForbidden,
                name: ROUTE_TRANSACTIONS,
                component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions.view.vue')
            },
            {
                path: '/notifications',
                beforeEnter: redirectOnForbidden,
                name: ROUTE_NOTIFICATIONS,
                component: () => import(/* webpackChunkName: "notifications" */ '../views/Notification.view.vue')
            },
            {
                path: '/companies',
                name: ROUTE_COMPANY,
                component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.view.vue')
            },
            {
                path: '/billing',
                name: ROUTE_BILLING,
                component: () => import(/* webpackChunkName: "billing" */ '../views/Billing.view.vue')
            }
        ]
    },
    /**
     * Matches everything which were not matches before...
     */
    {
        path: '*',
        redirect: { name: 'home' }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
