import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Company from '@/models/Company';

export default class CompanyRepository extends EntityBaseRepository {

    public list(withDeleted: boolean): Promise<AxiosResponse> {
        return this.axiosClient.get(`company?withDeleted=${withDeleted}`);
    }

    public get(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`company/${payload.id}`);
    }

    public create(payload: { company: Company }): Promise<AxiosResponse> {
        return this.axiosClient.post('company', payload.company);
    }

    public update(payload: { company: Company }): Promise<AxiosResponse> {
        return this.axiosClient.patch(`company/${payload.company.id}`, payload.company);
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`company/${payload.id}`);
    }

    public recycle(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`company/${payload.id}/recycle`);
    }

    public resendInvite(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.post(`company/${payload.id}/resendInvite`);
    }
}
