import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/interfaces/storeStateInterfaces';
import authStore from '@/store/auth.store';
import applicationStore from '@/store/application.store';
import companyStore from '@/store/company.store';
import driverStore from '@/store/driver.store';
import rideStore from '@/store/ride.store';
import taxiStore from '@/store/taxi.store';
import transactionsStore from '@/store/transaction.store';
import VuexPersistence from 'vuex-persist';
import shareStore from '@/store/share.store';
import managerStore from '@/store/manager.store';
import promoterStore from '@/store/promoter.store';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
    storage: window.localStorage,
    key: process.env.VUE_APP_VUEX_KEY
});

const store: StoreOptions<RootState> = {
    modules: {
        auth: {
            namespaced: true,
            ...authStore
        },
        application: {
            namespaced: true,
            ...applicationStore
        },
        company: {
            namespaced: true,
            ...companyStore
        },
        driver: {
            namespaced: true,
            ...driverStore
        },
        ride: {
            namespaced: true,
            ...rideStore
        },
        taxi: {
            namespaced: true,
            ...taxiStore
        },
        transactions: {
            namespaced: true,
            ...transactionsStore
        },
        share: {
            namespaced: true,
            ...shareStore
        },
        manager: {
            namespaced: true,
            ...managerStore
        },
        promoter: {
            namespaced: true,
            ...promoterStore
        }
    },
    plugins: [vuexLocal.plugin]
};

export default new Vuex.Store<RootState>(store);
