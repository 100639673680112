import { ManagerState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import ManagerRepository from '@/api/repositories/ManagerRepository';
import Manager from '@/models/Manager';

const managerRepository: ManagerRepository = RepositoryFactory.get('manager');

function initialManagerState() {
    return {
        managers: []
    };
}

const store: ManagerState = initialManagerState();

export enum managerStoreMutations {
    SAVE_MANAGERS = 'SAVE_TRANSACTIONS',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum managerStoreActions {
    GET = 'GET',
    CHANGE_STATUS = 'CHANGE_STATUS',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<ManagerState, RootState> = {
    [managerStoreActions.GET]: async ({ commit }): Promise<Manager[]> => {
        const response = await managerRepository.list();
        const managers = Manager.parseFromArray(response.data) as Manager[];
        commit(managerStoreMutations.SAVE_MANAGERS, managers);
        return managers;
    },
    [managerStoreActions.CREATE]: async ({ commit }, payload: Manager): Promise<Manager> => {
        const response = await managerRepository.create(payload);
        return Manager.parseFromObject(response.data);
    },
    [managerStoreActions.UPDATE]: async ({ commit }, payload: Manager): Promise<Manager> => {
        const response = await managerRepository.update(payload);
        return Manager.parseFromObject(response.data);
    },
    [managerStoreActions.CHANGE_STATUS]: async ({ commit }, payload: Manager): Promise<Manager> => {
        const newStatus = !payload.status;
        const response = await managerRepository.changeStatus(payload.id, newStatus);
        return Manager.parseFromObject(response.data);
    },
    [managerStoreActions.DELETE]: async ({ commit }, id: string): Promise<void> => {
        await managerRepository.delete(id);
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<ManagerState> = {
    [managerStoreMutations.SAVE_MANAGERS]: (state: ManagerState, managers: Manager[]) => {
        state.managers = managers;
    },
    [managerStoreMutations.CLEAR_STORE]: (state: ManagerState) => {
        state.managers = [];
    }
};

/**
 * GETTER SECTION
 */
export enum managerStoreGetter {
    MANAGERS = 'MANAGERS'
}

const getters: GetterTree<ManagerState, RootState> = {
    [managerStoreGetter.MANAGERS]: (state: ManagerState) => {
        return state.managers;
    }
};

const managersStore: Module<ManagerState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default managersStore;
