import axios, { AxiosInstance } from 'axios';
import { Vue } from 'vue-property-decorator';
import ApiError from '@/api/misc/ApiError';

/**
 * Base repository for auth repositories
 */
export default class AuthBaseRepository {

    protected axiosClient!: AxiosInstance;
    protected baseURL: string = process.env.VUE_APP_AUTH_URL!;

    constructor() {
        this.createHttpClient();
        this.setResponseInterceptors();
        console.info('using host: ', this.baseURL);
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        this.axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    console.log(error, error.response);
                    // check for "special" errors that need special treatment
                    if (error.response.status >= 500) {
                        switch (error.response.status) {
                            // Further cases ...
                            default: // 500 > any server error
                                Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
                        }
                        return new Promise(() => {
                            // cancels process of the interceptor chain
                        });
                    } else {
                        throw new ApiError(error.response.status, error.message, error.response.data.data);
                    }
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
                    return new Promise(() => {
                        // cancels processing of the interceptor chain
                    });
                }
            }
        );
    }

}
