import Parseable from '@/misc/Parseable';
import User from '@/models/User';

export enum COMPANY_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export default class Company extends Parseable {
    public id!: string;
    public name!: string;
    public street!: string;
    public postBox!: string;
    public city!: string;
    public phone!: string;
    public email!: string;
    public isDeleted!: boolean;
    public status: boolean = true;

    public manager!: User;

    public static parseFromObject(object: Partial<Company>): Company {
        const company = new Company();
        Object.assign(company, object);

        if (object.manager) {
            company.manager = User.parseFromObject(object.manager);
        }

        return company;
    }

    public parseToObject(): Partial<Company> {
        const tmp = { ...this };
        return tmp;
    }
}
