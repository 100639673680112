import Parseable from '@/misc/Parseable';
import Driver from '@/models/Driver';
import Taxi from '@/models/Taxi';

export default class DriverTaxi extends Parseable {
    public id!: string;
    public driver!: Driver | string;
    public taxi!: Taxi | string;
    public startTime!: Date;
    public endTime!: Date;

    public static parseFromObject(object: Partial<DriverTaxi>): DriverTaxi {
        const driverTaxi = new DriverTaxi();
        Object.assign(driverTaxi, object);

        if (object.driver && (typeof object.driver !== 'string')) {
            driverTaxi.driver = Driver.parseFromObject(object.driver);
        }

        if (object.taxi && (typeof object.taxi !== 'string')) {
            driverTaxi.taxi = Taxi.parseFromObject(object.taxi);
        }

        return driverTaxi;
    }

    public parseToObject(): Partial<DriverTaxi> {
        const tmp = { ...this };
        return tmp;
    }
}
