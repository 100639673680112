import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './styles/main.scss';
import '@/misc/CustomFilter';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ConfirmDialogComponent from '@/components/ConfirmDialogComponent.vue';
import CustomNotifications from '@/plugins/custom-notifications/CustomNotifications.plugin';
import AuthorizationPlugin from '@/plugins/Authorization.plugin';

// @ts-ignore
import VueCountryCode from 'vue-country-code';

Vue.config.productionTip = false;

Vue.use(CustomNotifications);
Vue.use(AuthorizationPlugin);
Vue.use(VueCountryCode);

Vue.component('LoadingComponent', LoadingComponent);
Vue.component('ConfirmDialogComponent', ConfirmDialogComponent);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
