import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import Company from '@/models/Company';
import { AxiosResponse } from 'axios';

export default class RideRepository extends EntityBaseRepository {
    public list(company: Company): Promise<AxiosResponse> {
        return this.axiosClient.get(`ride?company=${company.id}`);
    }

    public get(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`ride/${payload.id}`);
    }

    public companyRides(payload: {id: string, from: string, to: string}): Promise<AxiosResponse> {
        return this.axiosClient.get(`ride/company/${payload.id}?from=${payload.from}&to=${payload.to}`);
    }

    public canceledRides(from: string | null, to: string | null): Promise<AxiosResponse> {
        let url = 'ride/canceled';
        if (from && to) {
            url += `?from=${from}&to=${to}`;
        }

        return this.axiosClient.get(url);
    }
}
