import AuthBaseRepository from '@/api/misc/AuthBaseRepository';
import { AxiosResponse } from 'axios';
import User from '@/models/User';

export default class AuthRepository extends AuthBaseRepository {

    public login(payload: { login: string, password: string }): Promise<AxiosResponse> {
        return this.axiosClient.post('auth/manager', payload);
    }

    public async getByInviteCode(payload: { inviteCode: string }): Promise<User> {
        const response = await this.axiosClient.get(`auth/getByInviteCode?inviteCode=${payload.inviteCode}`);
        return User.parseFromObject(response.data);
    }

    public async setManagerPassword(payload: { inviteCode: string, password: string }): Promise<User> {
        const response = await this.axiosClient.post('auth/setManagerPassword', payload);
        return User.parseFromObject(response.data);
    }
}
