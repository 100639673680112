import AuthRepository from '@/api/repositories/AuthRepository';
import UserRepository from '@/api/repositories/UserRepository';
import TaxiRepository from '@/api/repositories/TaxiRepository';
import DriverRepository from '@/api/repositories/DriverRepository';
import CompanyRepository from '@/api/repositories/CompanyRepository';
import RideRepository from '@/api/repositories/RideRepository';
import DashboardRepository from '@/api/repositories/DashboardRepository';
import BillingRepository from '@/api/repositories/BillingRepository';
import TransactionRepository from '@/api/repositories/TransactionRepository';
import ShareRepository from '@/api/repositories/ShareRepository';
import ManagerRepository from '@/api/repositories/ManagerRepository';
import StatisticsRepository from '@/api/repositories/StatisticsRepository';
import NotificationRepository from '@/api/repositories/NotificationRepository';
import PromoterRepository from '@/api/repositories/PromoterRepository';

const repositories: any = {
    auth: { Repository: AuthRepository, instance: null },
    company: { Repository: CompanyRepository, instance: null },
    driver: { Repository: DriverRepository, instance: null },
    taxi: { Repository: TaxiRepository, instance: null },
    user: { Repository: UserRepository, instance: null },
    ride: { Repository: RideRepository, instance: null },
    dashboard: { Repository: DashboardRepository, instance: null },
    billing: { Repository: BillingRepository, instance: null },
    transactions: { Repository: TransactionRepository, instance: null },
    share: { Repository: ShareRepository, instance: null },
    manager: { Repository: ManagerRepository, instance: null },
    statistics: { Repository: StatisticsRepository, instance: null },
    notifications: { Repository: NotificationRepository, instance: null },
    promoter: { Repository: PromoterRepository, instance: null }
};
/**
 *  Factory to create repositories. All created repositories are singleton instances.
 */
export const RepositoryFactory = {
    get: (name: string) => {
        if (repositories[name].instance) {
            return repositories[name].instance;
        } else {
            return new repositories[name].Repository();
        }
    }
};
