import { PromoterState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import PromoterRepository from '@/api/repositories/PromoterRepository';
import Promoter from '@/models/Promoter';
import PromoterStatistics from '@/models/PromoterStatistics';

const promoterRepository: PromoterRepository = RepositoryFactory.get('promoter');

function initialPromoterState() {
    return {
        promoters: []
    };
}

const store: PromoterState = initialPromoterState();

export enum promoterStoreMutations {
    SAVE_PROMOTERS = 'SAVE_PROMOTERS',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum promoterStoreActions {
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    STATISTICS = 'STATISTICS'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<PromoterState, RootState> = {
    [promoterStoreActions.GET]: async ({ commit }, payload: {from: string, to: string}): Promise<Promoter[]> => {
        const response = await promoterRepository.list(payload);
        const promoters = Promoter.parseFromArray(response.data) as Promoter[];
        commit(promoterStoreMutations.SAVE_PROMOTERS, promoters);
        return promoters;
    },
    [promoterStoreActions.CREATE]: async ({ commit }, payload: Promoter): Promise<Promoter> => {
        const response = await promoterRepository.create(payload);
        return Promoter.parseFromObject(response.data);
    },
    [promoterStoreActions.UPDATE]: async ({ commit }, payload: Promoter): Promise<Promoter> => {
        const response = await promoterRepository.update(payload);
        return Promoter.parseFromObject(response.data);
    },
    [promoterStoreActions.DELETE]: async ({ commit }, id: string): Promise<void> => {
        await promoterRepository.delete(id);
    },
    [promoterStoreActions.STATISTICS]: async ({ commit }, id: string): Promise<PromoterStatistics> => {
        const response = await promoterRepository.getStatistics(id);
        return PromoterStatistics.parseFromObject(response.data);
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<PromoterState> = {
    [promoterStoreMutations.SAVE_PROMOTERS]: (state: PromoterState, promoters: Promoter[]) => {
        state.promoters = promoters;
    },
    [promoterStoreMutations.CLEAR_STORE]: (state: PromoterState) => {
        state.promoters = [];
    }
};

/**
 * GETTER SECTION
 */
export enum promoterStoreGetters {
    PROMOTERS = 'PROMOTERS'
}

const getters: GetterTree<PromoterState, RootState> = {
    [promoterStoreGetters.PROMOTERS]: (state: PromoterState) => {
        return state.promoters;
    }
};

const promotersStore: Module<PromoterState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default promotersStore;
