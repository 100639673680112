import { CompanyState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import CompanyRepository from '@/api/repositories/CompanyRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Company from '@/models/Company';

const companyRepository: CompanyRepository = RepositoryFactory.get('company');

function initialCompanyState(): CompanyState {
    return {
        companies: [],
        company: undefined
    };
}

const store: CompanyState = initialCompanyState();

export enum companyStoreMutations {
    SAVE_COMPANIES= 'SAVE_COMPANIES',
    SAVE_COMPANY= 'SAVE_COMPANY',
    REMOVE_COMPANY = 'REMOVE_COMPANY',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum companyStoreActions {
    LIST = 'LIST',
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum companyStoreGetter {
    COMPANIES = 'COMPANIES'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<CompanyState, RootState> = {
    [companyStoreActions.LIST]: async ({ commit }, payload: { withDeleted: boolean }): Promise<Company[]> => {
        const response = await companyRepository.list(payload.withDeleted);
        // Save companies
        const companies = Company.parseFromArray(response.data) as Company[];
        commit(companyStoreMutations.SAVE_COMPANIES, companies);
        return companies;
    },
    [companyStoreActions.GET]: async ({ commit }, payload: { id: string }): Promise<Company> => {
        const response = await companyRepository.get({ id: payload.id });
        const company = Company.parseFromObject(response.data) as Company;
        return company;
    },
    [companyStoreActions.CREATE]: async ({ commit }, payload: { company: Company }): Promise<Company> => {
        const response = await companyRepository.create({ company: payload.company });
        const company = Company.parseFromObject(response.data) as Company;
        return company;
    },
    [companyStoreActions.UPDATE]: async ({ commit }, payload: { company: Company }): Promise<Company> => {
        const response = await companyRepository.update({ company: payload.company });
        const company = Company.parseFromObject(response.data) as Company;
        return company;
    },
    [companyStoreActions.DELETE]: async ({ commit }, payload: { company: Company }): Promise<Company> => {
        const response = await companyRepository.delete({ id: payload.company.id });
        const company = Company.parseFromObject(response.data) as Company;
        commit(companyStoreMutations.REMOVE_COMPANY, company);
        return company;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<CompanyState> = {
    [companyStoreMutations.SAVE_COMPANIES]: (state: CompanyState, value: Company[]) => {
        state.companies = value;
    },
    [companyStoreMutations.SAVE_COMPANY]: (state: CompanyState, value: Company) => {
        state.company = value;
    },
    [companyStoreMutations.REMOVE_COMPANY]: (state: CompanyState, value: Company) => {
        state.companies = state.companies.filter(company => company.id !== value.id);
    },
    [companyStoreMutations.CLEAR_STORE]: (state: CompanyState) => {
        state.companies = [];
        state.company = undefined;
    }
};

/**
 * GETTER SECTION
 */
const getters: GetterTree<CompanyState, RootState> = {
    [companyStoreGetter.COMPANIES]: (state: CompanyState) => {
        return state.companies;
    }
};

const companyStore: Module<CompanyState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default companyStore;
