import Parseable from '@/misc/Parseable';

export default class ShareConfiguration extends Parseable {
    public id!: string;
    public pricePerRide!: number;
    public pricePerRideActive!: boolean;
    public updatedAt!: string;

    public static parseFromObject(object: Partial<ShareConfiguration>): ShareConfiguration {
        const config = new ShareConfiguration();
        Object.assign(config, object);

        return config;
    }

    public parseToObject(): Partial<ShareConfiguration> {
        const tmp = { ...this };
        return tmp;
    }
}
