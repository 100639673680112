import { RootState, TaxiState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import TaxiRepository from '@/api/repositories/TaxiRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Company from '@/models/Company';
import Taxi from '@/models/Taxi';

const taxiRepository: TaxiRepository = RepositoryFactory.get('taxi');

function initialTaxiState(): TaxiState {
    return {
        taxis: [],
        taxi: undefined
    };
}

const store: TaxiState = initialTaxiState();

export enum taxiStoreMutations {
    SAVE_TAXIS = 'SAVE_TAXIS',
    SAVE_TAXI = 'SAVE_TAXI',
    REMOVE_TAXI = 'REMOVE_TAXI',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum taxiStoreActions {
    LIST = 'LIST',
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<TaxiState, RootState> = {
    [taxiStoreActions.LIST]: async ({ commit }, payload: { company: Company }): Promise<Taxi[]> => {
        const response = await taxiRepository.list(payload.company);
        // Save taxis
        const taxis = Taxi.parseFromArray(response.data) as Taxi[];
        commit(taxiStoreMutations.SAVE_TAXIS, taxis);
        return taxis;
    },
    [taxiStoreActions.GET]: async ({ commit }, payload: { id: string }): Promise<Taxi> => {
        const response = await taxiRepository.get({ id: payload.id });
        const taxi = Taxi.parseFromObject(response.data) as Taxi;
        return taxi;
    },
    [taxiStoreActions.CREATE]: async ({ commit },
        payload: { taxi: Taxi, company: Company }): Promise<Taxi> => {
        const response = await taxiRepository.create({ taxi: payload.taxi, company: payload.company });
        const taxi = Taxi.parseFromObject(response.data) as Taxi;
        return taxi;
    },
    [taxiStoreActions.UPDATE]: async ({ commit }, payload: { taxi: Taxi }): Promise<Taxi> => {
        const response = await taxiRepository.update({ taxi: payload.taxi });
        const taxi = Taxi.parseFromObject(response.data) as Taxi;
        return taxi;
    },
    [taxiStoreActions.DELETE]: async ({ commit }, payload: { taxi: Taxi }): Promise<Taxi> => {
        const response = await taxiRepository.delete({ id: payload.taxi.id });
        const taxi = Taxi.parseFromObject(response.data) as Taxi;
        commit(taxiStoreMutations.REMOVE_TAXI, taxi);
        return taxi;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<TaxiState> = {
    [taxiStoreMutations.SAVE_TAXIS]: (state: TaxiState, taxis: Taxi[]) => {
        state.taxis = taxis;
    },
    [taxiStoreMutations.SAVE_TAXI]: (state: TaxiState, taxi: Taxi) => {
        state.taxi = taxi;
    },
    [taxiStoreMutations.REMOVE_TAXI]: (state: TaxiState, value: Taxi) => {
        state.taxis = state.taxis.filter(taxi => taxi.id !== value.id);
    },
    [taxiStoreMutations.CLEAR_STORE]: (state: TaxiState) => {
        state.taxis = [];
        state.taxi = undefined;
    }
};

/**
 * GETTER SECTION
 */
export enum taxiStoreGetter {
    TAXIS = 'TAXIS'
}

const getters: GetterTree<TaxiState, RootState> = {
    [taxiStoreGetter.TAXIS]: (state: TaxiState) => {
        return state.taxis;
    }
};

const taxiStore: Module<TaxiState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default taxiStore;
