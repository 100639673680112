import Parseable from '@/misc/Parseable';
import { UserRoles } from '@/enum/UserRoles';
import Company from '@/models/Company';

export default class User extends Parseable {
    public id?: string;
    public login?: string;
    public inviteCode?: string;
    public hasSetPassword!: boolean;
    public isAdmin?: boolean;
    public role?: UserRoles;

    /**
     * The company id of the users company. If the user is an admin, this property is empty.
     */
    public company?: Company | string;

    public static parseFromObject(object: any): User {
        const user = new User();

        if (object.company && (typeof object.company !== 'string')) {
            user.company = Company.parseFromObject(object.company);
        }

        Object.assign(user, object);
        return user;
    }

    public parseToObject(): Partial<User> {
        const tmp = { ...this };
        return tmp;
    }

}
