import axios, { AxiosInstance } from 'axios';
import { Vue } from 'vue-property-decorator';
import ApiError from '@/api/misc/ApiError';
import store from './../../store';
import router from '@/router';
import { authStoreGetter } from '@/store/auth.store';
import { ROUTE_LOGIN } from '@/router/routes';

/**
 * Base repository class
 */
export default class EntityBaseRepository {

    protected axiosClient!: AxiosInstance;
    protected baseURL: string = process.env.VUE_APP_API_URL!;

    constructor() {
        this.createHttpClient();
        this.setRequestInterceptors();
        this.setResponseInterceptors();
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL
        });
    }

    /**
     * Sets the request interceptors
     * @private
     */
    private setRequestInterceptors() {
        this.axiosClient.interceptors.request.use((config) => {
            // OPTIMIZE this is not optimal, the token could be expired ...
            if (store.getters[`auth/${authStoreGetter.TOKEN}`]) {
                config.headers.authorization = `Bearer ${store.getters[`auth/${authStoreGetter.TOKEN}`]}`;
            }
            return config;
        }, (error) => {
            throw Promise.reject(error);
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        // normalize response
        this.axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403 || error.response.status >= 500) {
                        switch (error.response.status) {
                            case 401:
                            case 403:
                                Object
                                    // @ts-ignore
                                    .keys(store._modulesNamespaceMap)
                                    .forEach(key => {
                                        store.commit(`${key}CLEAR_STORE`);
                                    });
                                router.push({ name: ROUTE_LOGIN });
                                break;
                            default: // 500 > any server error
                                Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
                        }
                        return new Promise(() => {
                            // cancels process of the interceptor chain
                        });
                    } else {
                        throw new ApiError(error.response.status, error.message, error.response.data.data);
                    }
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
                    return new Promise(() => {
                        // cancels processing of the interceptor chain
                    });
                }
            }
        );
    }
}
