import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import Company from '@/models/Company';
import { AxiosResponse } from 'axios';
import Taxi from '@/models/Taxi';

export default class TaxiRepository extends EntityBaseRepository {
    public list(company: Company): Promise<AxiosResponse> {
        return this.axiosClient.get(`taxi?company=${company.id}`);
    }

    public get(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`taxi/${payload.id}`);
    }

    public create(payload: { taxi: Taxi, company: Company }): Promise<AxiosResponse> {
        return this.axiosClient.post(
            `taxi?company=${payload.company.id}`, payload.taxi);
    }

    public update(payload: { taxi: Taxi }): Promise<AxiosResponse> {
        return this.axiosClient.patch(`taxi/${payload.taxi.id}`, payload.taxi);
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`taxi/${payload.id}`);
    }
}
