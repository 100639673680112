/**
 * All application routes are collected here
 */
export const ROUTE_LOGIN = 'login';
export const ROUTE_INVITE = 'invite';
export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_HOME = 'home';
export const ROUTE_DRIVER = 'driver';
export const ROUTE_COMPANY = 'company';
export const ROUTE_TAXI = 'taxi';
export const ROUTE_RIDE = 'ride';
export const ROUTE_BILLING = 'billing';
export const ROUTE_STATISTICS = 'statistics';
export const ROUTE_TRANSACTIONS = 'transactions';
export const ROUTE_MANAGER_MANAGEMENT = 'managers';
export const ROUTE_SHARE_MANAGEMENT = 'share-management';
export const ROUTE_NOTIFICATIONS = 'notifications';
export const ROUTE_PROMOTERS = 'promoters';
