

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmDialogComponent extends Vue {
    @Prop({ default: false })
    public isLoading!: boolean;

    @Prop({ default: false })
    public showDialog!: boolean;

    @Prop({
        default: function () {
            return (this as any).$t('GENERAL.CONFIRM_DIALOG.DEFAULT_TITLE');
        }
    })
    public title!: string;

    @Prop({
        default: function () {
            return (this as any).$t('GENERAL.CONFIRM_DIALOG.DEFAULT_TEXT');
        }
    })
    public text!: string;

    public confirmed() {
        this.$emit('confirmed');
    }

    public closed() {
        this.$emit('closed');
    }
}

