import Parseable from '@/misc/Parseable';

export default class Promoter extends Parseable {
    public id!: string;
    public firstName!: string;
    public lastName!: string;
    public mail!: string;
    public phone!: string;
    public passengerCount!: number;
    public rideCount!: number;

    public static parseFromObject(object: Partial<Promoter>): Promoter {
        const promoter = new Promoter();
        Object.assign(promoter, object);
        return promoter;
    }

    public get name() {
        return `${this.firstName} ${this.lastName}`;
    }

    public parseToObject(): Partial<Promoter> {
        const tmp = { ...this };
        return tmp;
    }
}
