import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import Company from '@/models/Company';
import { AxiosResponse } from 'axios';

export default class ShareRepository extends EntityBaseRepository {
    public getConfiguration(): Promise<AxiosResponse> {
        return this.axiosClient.get('share');
    }

    public setShare(price: number): Promise<AxiosResponse> {
        return this.axiosClient.put('share/price-per-ride', { price });
    }

    public setIsShareActive(active: boolean): Promise<AxiosResponse> {
        return this.axiosClient.put('share/active', { active });
    }
}
