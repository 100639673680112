import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Company from '@/models/Company';
import Driver from '@/models/Driver';

export default class DriverRepository extends EntityBaseRepository {
    public list(payload: { company: Company, from: string, to: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`driver?company=${payload.company.id}&from=${payload.from}&to=${payload.to}`);
    }

    public get(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`driver/${payload.id}`);
    }

    public create(payload: { driver: Driver, company: Company }): Promise<AxiosResponse> {
        return this.axiosClient.post(
            `driver/createDriverForCompany?company=${payload.company.id}`, payload.driver.parseToObject());
    }

    public update(payload: { driver: Driver }): Promise<AxiosResponse> {
        return this.axiosClient.patch(`driver/${payload.driver.id}`, payload.driver.parseToObject());
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`driver/${payload.id}`);
    }
}
