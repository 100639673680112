import Parseable from '@/misc/Parseable';

export default class Taxi extends Parseable {
    public id!: string;
    public plate!: string;
    public seats!: number;
    public taxiNo!: string;
    public type!: string;

    public static parseFromObject(object: Partial<Taxi>): Taxi {
        const taxi = new Taxi();
        Object.assign(taxi, object);
        return taxi;
    }

    public parseToObject(): Partial<Taxi> {
        const tmp = { ...this };
        return tmp;
    }
}
