import { RootState, TransactionState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Transaction from '@/models/Transaction';
import TransactionRepository from '@/api/repositories/TransactionRepository';

const transactionsRepository: TransactionRepository = RepositoryFactory.get('transactions');

function initialTransactionState() {
    return {
        transactions: []
    };
}

const store: TransactionState = initialTransactionState();

export enum transactionStoreMutations {
    SAVE_TRANSACTIONS = 'SAVE_TRANSACTIONS',
    ADD_TRANSACTION = 'ADD_TRANSACTION',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum transactionStoreActions {
    GET = 'GET',
    CREATE = 'CREATE',
    SET_CREDIT = 'SET_CREDIT'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<TransactionState, RootState> = {
    [transactionStoreActions.CREATE]: async ({ commit }, payload: Transaction): Promise<Transaction> => {
        const response = await transactionsRepository.createTransaction(payload);
        const transaction = Transaction.parseFromObject(response.data);
        commit(transactionStoreMutations.ADD_TRANSACTION, transaction);
        return transaction;
    },
    [transactionStoreActions.SET_CREDIT]: async ({ commit }, payload: Transaction): Promise<Transaction> => {
        const response = await transactionsRepository.setCredit(payload);
        const transaction = Transaction.parseFromObject(response.data);
        commit(transactionStoreMutations.ADD_TRANSACTION, transaction);
        return transaction;
    },
    [transactionStoreActions.GET]: async ({ commit }, payload: {start: string, end: string}): Promise<Transaction[]> => {
        const response = await transactionsRepository.get(payload);
        const transactions = Transaction.parseFromArray(response.data) as Transaction[];
        commit(transactionStoreMutations.SAVE_TRANSACTIONS, transactions);
        return transactions;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<TransactionState> = {
    [transactionStoreMutations.SAVE_TRANSACTIONS]: (state: TransactionState, transactions: Transaction[]) => {
        state.transactions = transactions;
    },
    [transactionStoreMutations.ADD_TRANSACTION]: (state: TransactionState, transaction: Transaction) => {
        state.transactions.push(transaction);
    },
    [transactionStoreMutations.CLEAR_STORE]: (state: TransactionState) => {
        state.transactions = [];
    }
};

/**
 * GETTER SECTION
 */
export enum transactionStoreGetter {
    TRANSACTIONS = 'TRANSACTIONS'
}

const getters: GetterTree<TransactionState, RootState> = {
    [transactionStoreGetter.TRANSACTIONS]: (state: TransactionState) => {
        return state.transactions;
    }
};

const transactionsStore: Module<TransactionState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default transactionsStore;
