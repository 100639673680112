import { ApplicationState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Company from '@/models/Company';

function initialApplicationState(): ApplicationState {
    return {
        selectedCompany: undefined
    };
}

const store: ApplicationState = initialApplicationState();

/**
 * ACTION SECTION
 */
export enum applicationStoreActions {}

const actions: ActionTree<ApplicationState, RootState> = {
};

/**
 * MUTATION SECTION
 */
export enum applicationStoreMutations {
    SET_COMPANY= 'SET_COMPANY',
    CLEAR_STORE= 'CLEAR_STORE'
}

const mutations: MutationTree<ApplicationState> = {
    [applicationStoreMutations.SET_COMPANY]: (state: ApplicationState, company: Company) => {
        state.selectedCompany = company;
    },
    [applicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
        state.selectedCompany = undefined;
    }
};

/**
 * GETTER SECTION
 */
export enum applicationStoreGetter {
    SELECTED_COMPANY= 'SELECTED_COMPANY'
}

const getters: GetterTree<ApplicationState, RootState> = {
    [applicationStoreGetter.SELECTED_COMPANY]: (state: ApplicationState) => state.selectedCompany
};

const applicationStore: Module<ApplicationState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default applicationStore;
