import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';

export default class BillingRepository extends EntityBaseRepository {
    public get(payload: { from: string, to: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`billing?from=${payload.from}&to=${payload.to}`);
    }

    public generatePDF(payload: { company: string, from: string, to: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(
            `billing/generatePDF?from=${payload.from}&to=${payload.to}&company=${payload.company}`,
            { responseType: 'arraybuffer' });
    }

    public generateCompanyPDF(payload: { company: string, from: string, to: string}): Promise<AxiosResponse> {
        return this.axiosClient.get(`billing/generateCompanyPDF?from=${payload.from}&to=${payload.to}&company=${payload.company}`,
            { responseType: 'arraybuffer' });
    }
}
