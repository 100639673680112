import Parseable from '@/misc/Parseable';

export enum PAYMENT_TYPE {
    CASH = 'CASH',
    PAYTODAY = 'PAYTODAY'
}

export default class Payment extends Parseable {
    public id!: string;
    public transactionId?: string;
    public type!: PAYMENT_TYPE;

    public static parseFromObject(object: Partial<Payment>): Payment {
        const payment = new Payment();
        Object.assign(payment, object);

        return payment;
    }

    public parseToObject(): Partial<Payment> {
        const tmp = { ...this };
        return tmp;
    }
}
