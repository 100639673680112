import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Company from '@/models/Company';

export default class DashboardRepository extends EntityBaseRepository {

    public dashboardData(company: Company): Promise<AxiosResponse> {
        return this.axiosClient.get(`dashboard/dashboardData?company=${company.id}`);
    }
}
