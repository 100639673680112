import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Manager from '@/models/Manager';
import { UserRoles } from '@/enum/UserRoles';

export default class ManagerRepository extends EntityBaseRepository {
    public list(): Promise<AxiosResponse> {
        return this.axiosClient.get('manager');
    }

    public create(payload: Manager): Promise<AxiosResponse> {
        return this.axiosClient.post('manager/create', {
            login: payload.login,
            email: payload.email,
            role: UserRoles.MANAGER,
            status: payload.status
        });
    }

    public update(payload: Manager): Promise<AxiosResponse> {
        return this.axiosClient.patch(`manager/${payload.id}`, {
            login: payload.login,
            email: payload.email
        });
    }

    public changeStatus(id: string, status: boolean): Promise<AxiosResponse> {
        return this.axiosClient.patch(`manager/${id}/status`, {
            status
        });
    }

    public delete(id: string): Promise<AxiosResponse> {
        return this.axiosClient.delete(`manager/${id}`);
    }
}
