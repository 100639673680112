import Parseable from '@/misc/Parseable';
import Promoter from '@/models/Promoter';

export default class Driver extends Parseable {
    public id!: string;
    public firstName!: string;
    public lastName!: string;
    public phone!: string;
    public driverLicense!: string;
    public isVerified!: boolean;
    public status!: boolean;
    public active!: boolean;
    public submittedDocuments!: boolean;
    public credit!: number;
    public numberOfRides!: number;
    public passengerCount!: number;
    public transactionAmount!: number;
    public promoter?: string | Promoter;

    public static parseFromObject(object: Partial<Driver>): Driver {
        const driver = new Driver();
        Object.assign(driver, object);

        return driver;
    }

    public parseToObject(): Partial<Driver> {
        const tmp = { ...this };
        return tmp;
    }
}
