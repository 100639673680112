import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';

export default class StatisticsRepository extends EntityBaseRepository {
    public getStatistics(): Promise<AxiosResponse> {
        return this.axiosClient.get('statistics/overall');
    }

    public getSales(from: string, to: string): Promise<AxiosResponse> {
        return this.axiosClient.get(`statistics/sales?from=${from}&to=${to}`);
    }
}
