import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Promoter from '@/models/Promoter';

export default class PromoterRepository extends EntityBaseRepository {
    public list(payload: {from: string, to: string}): Promise<AxiosResponse> {
        return this.axiosClient.get(`promoter?from=${payload.from}&to=${payload.to}`);
    }

    public create(payload: Promoter): Promise<AxiosResponse> {
        return this.axiosClient.post('promoter', payload);
    }

    public update(payload: Promoter): Promise<AxiosResponse> {
        return this.axiosClient.patch(`promoter/${payload.id}`, payload);
    }

    public delete(id: string): Promise<AxiosResponse> {
        return this.axiosClient.delete(`promoter/${id}`);
    }

    public getStatistics(id: string): Promise<AxiosResponse> {
        return this.axiosClient.get(`promoter/${id}/statistics`);
    }
}
