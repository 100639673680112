import { DriverState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import DriverRepository from '@/api/repositories/DriverRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Company from '@/models/Company';
import Driver from '@/models/Driver';

const driverRepository: DriverRepository = RepositoryFactory.get('driver');

function initialDriverState() {
    return {
        drivers: [],
        driver: undefined
    };
}

const store: DriverState = initialDriverState();

export enum driverStoreMutations {
    SAVE_DRIVERS = 'SAVE_DRIVERS',
    SAVE_DRIVER = 'SAVE_DRIVER',
    REMOVE_DRIVER = 'REMOVE_DRIVER',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum driverStoreActions {
    LIST = 'LIST',
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<DriverState, RootState> = {
    [driverStoreActions.LIST]: async ({ commit }, payload: { company: Company, from: string, to: string }): Promise<Driver[]> => {
        const response = await driverRepository.list({ company: payload.company, from: payload.from, to: payload.to });
        // Save drivers
        const drivers = Driver.parseFromArray(response.data) as Driver[];
        commit(driverStoreMutations.SAVE_DRIVERS, drivers);
        return drivers;
    },
    [driverStoreActions.GET]: async ({ commit }, payload: { id: string }): Promise<Driver> => {
        const response = await driverRepository.get({ id: payload.id });
        const driver = Driver.parseFromObject(response.data) as Driver;
        return driver;
    },
    [driverStoreActions.CREATE]: async ({ commit },
        payload: { driver: Driver, company: Company }): Promise<Driver> => {
        const response = await driverRepository.create({ driver: payload.driver, company: payload.company });
        const driver = Driver.parseFromObject(response.data) as Driver;
        return driver;
    },
    [driverStoreActions.UPDATE]: async ({ commit }, payload: { driver: Driver }): Promise<Driver> => {
        const response = await driverRepository.update({ driver: payload.driver });
        const driver = Driver.parseFromObject(response.data) as Driver;
        return driver;
    },
    [driverStoreActions.DELETE]: async ({ commit }, payload: { driver: Driver }): Promise<Driver> => {
        const response = await driverRepository.delete({ id: payload.driver.id });
        const driver = Driver.parseFromObject(response.data) as Driver;
        commit(driverStoreMutations.REMOVE_DRIVER, driver);
        return driver;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<DriverState> = {
    [driverStoreMutations.SAVE_DRIVERS]: (state: DriverState, drivers: Driver[]) => {
        state.drivers = drivers;
    },
    [driverStoreMutations.SAVE_DRIVER]: (state: DriverState, driver: Driver) => {
        state.driver = driver;
    },
    [driverStoreMutations.REMOVE_DRIVER]: (state: DriverState, value: Driver) => {
        state.drivers = state.drivers.filter(driver => driver.id !== value.id);
    },
    [driverStoreMutations.CLEAR_STORE]: (state: DriverState) => {
        state.drivers = [];
        state.driver = undefined;
    }
};

/**
 * GETTER SECTION
 */
export enum driverStoreGetter {
    DRIVERS = 'DRIVERS'
}

const getters: GetterTree<DriverState, RootState> = {
    [driverStoreGetter.DRIVERS]: (state: DriverState) => {
        return state.drivers;
    }
};

const driverStore: Module<DriverState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default driverStore;
