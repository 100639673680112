import { RideState, RootState, ShareState, TaxiState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Company from '@/models/Company';
import RideRepository from '@/api/repositories/RideRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Ride from '@/models/Ride';
import ShareRepository from '@/api/repositories/ShareRepository';
import ShareConfiguration from '@/models/ShareConfiguration';

const shareRepository: ShareRepository = RepositoryFactory.get('share');

function initialShareState(): ShareState {
    return {
        configuration: ShareConfiguration.parseFromObject({
            pricePerRide: undefined,
            pricePerRideActive: undefined
        })
    };
}

const store: ShareState = initialShareState();

export enum shareStoreActions {
    GET_CONFIGURATION = 'GET_CONFIGURATION',
    SET_PRICE_PER_RIDE = 'SET_PRICE_PER_RIDE',
    SET_PRICE_PER_RIDE_ACTIVE = 'SET_PRICE_PER_RIDE_ACTIVE'
}

export enum shareStoreMutations {
    SET_SHARE_CONFIGURATION = 'SET_SHARE_CONFIGURATION',
    CLEAR_STORE = 'CLEAR_STORE'
}

export enum shareStoreGetter {
    SHARE = 'SHARE',
    IS_SHARE_ACTIVE = 'IS_SHARE_ACTIVE'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<ShareState, RootState> = {
    [shareStoreActions.GET_CONFIGURATION]: async ({ commit }): Promise<ShareConfiguration> => {
        const response = await shareRepository.getConfiguration();
        const share = ShareConfiguration.parseFromObject(response.data);
        commit(shareStoreMutations.SET_SHARE_CONFIGURATION, share);
        return share;
    },
    [shareStoreActions.SET_PRICE_PER_RIDE]: async ({ commit }, price: number): Promise<ShareConfiguration> => {
        const response = await shareRepository.setShare(price);
        const share = ShareConfiguration.parseFromObject(response.data);
        commit(shareStoreMutations.SET_SHARE_CONFIGURATION, share);
        return share;
    },
    [shareStoreActions.SET_PRICE_PER_RIDE_ACTIVE]: async ({ commit }, active: boolean): Promise<ShareConfiguration> => {
        const response = await shareRepository.setIsShareActive(active);
        const share = ShareConfiguration.parseFromObject(response.data);
        commit(shareStoreMutations.SET_SHARE_CONFIGURATION, share);
        return share;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<ShareState> = {
    [shareStoreMutations.SET_SHARE_CONFIGURATION]: (state: ShareState, configuration: ShareConfiguration) => {
        state.configuration = configuration;
    },
    [shareStoreMutations.CLEAR_STORE]: (state: ShareState) => {
        state = initialShareState();
    }
};

const getters: GetterTree<ShareState, RootState> = {
    [shareStoreGetter.SHARE]: (state: ShareState) => state.configuration.pricePerRide,
    [shareStoreGetter.IS_SHARE_ACTIVE]: (state: ShareState) => state.configuration.pricePerRideActive
};

const shareStore: Module<ShareState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default shareStore;
