import store from '@/store';
import { authStoreGetter } from '@/store/auth.store';
import User from '@/models/User';
import { UserRoles } from '@/enum/UserRoles';

const AuthorizationPlugin = {
    install(Vue: any, options: any = {}) {
        // Needs to be a function in order to be reactive
        const getCurrentUser = (): User | undefined => {
            const user = new User();
            Object.assign(user, store.getters[`auth/${authStoreGetter.CURRENT_USER}`]);
            return user;
        };
        Vue.prototype.$getCurrentAccount = getCurrentUser;
        Vue.getCurrentAccount = getCurrentUser;

        const hasRole = (role: UserRoles) => {
            const user: User | undefined = getCurrentUser();
            return user && user.role === role;
        };
        Vue.prototype.$hasRole = hasRole;
        Vue.hasRole = hasRole;

        const hasAnyRole = (roles: UserRoles[]) => {
            const user: User | undefined = getCurrentUser();
            if (!user?.role) return false;
            return user && roles.indexOf(user.role) !== -1;
        };
        Vue.prototype.$hasAnyRole = hasAnyRole;
        Vue.hasAnyRole = hasAnyRole;
    }
};

export default AuthorizationPlugin;
