import Parseable from '@/misc/Parseable';

export default class PromoterStatistics extends Parseable {
    public drivers!: string[];
    public companies!: string[];

    public static parseFromObject(object: Partial<PromoterStatistics>): PromoterStatistics {
        const statistics = new PromoterStatistics();
        Object.assign(statistics, object);
        return statistics;
    }

    public parseToObject(): Partial<PromoterStatistics> {
        const tmp = { ...this };
        return tmp;
    }
}
