import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import Company from '@/models/Company';
import Driver from '@/models/Driver';
import Transaction from '@/models/Transaction';

export default class TransactionRepository extends EntityBaseRepository {
    public get(payload: {start: string, end: string}): Promise<AxiosResponse> {
        return this.axiosClient.get(`transaction?from=${payload.start}&to=${payload.end}`);
    }

    public createTransaction(payload: Transaction): Promise<AxiosResponse> {
        return this.axiosClient.post('transaction', {
            manager: payload.manager.id,
            driver: payload.driver.id,
            amount: payload.amount,
            paymentMethod: payload.paymentMethod,
            description: payload.description
        });
    }

    public setCredit(payload: Transaction): Promise<AxiosResponse> {
        return this.axiosClient.put('transaction/set-credit', {
            manager: payload.manager.id,
            driver: payload.driver.id,
            credit: payload.amount,
            paymentMethod: payload.paymentMethod,
            description: payload.description
        });
    }
}
