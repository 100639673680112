import Parseable from '@/misc/Parseable';
import Request from '@/models/Request';
import DriverTaxi from '@/models/DriverTaxi';
import Driver from '@/models/Driver';

export default class Offer extends Parseable {
    public id!: string;
    public eta!: string;
    public price!: number;
    public status!: string;
    public createdAt!: Date;

    public history!: Array<{ id: string, date: Date, status: string }>;
    public driverTaxi!: DriverTaxi | string;
    public request!: Request | string;

    public static parseFromObject(object: Partial<Offer>): Offer {
        const offer = new Offer();
        Object.assign(offer, object);

        if (object.request && (typeof object.request !== 'string')) {
            offer.request = Request.parseFromObject(object.request);
        }

        if (object.driverTaxi && (typeof object.driverTaxi !== 'string')) {
            offer.driverTaxi = DriverTaxi.parseFromObject(object.driverTaxi);
        }

        return offer;
    }

    public parseToObject(): Partial<Offer> {
        const tmp = { ...this };
        return tmp;
    }
}
