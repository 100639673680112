import Parseable from '@/misc/Parseable';

export default class Customer extends Parseable {
    public id!: string;
    public createdAt!: Date;

    public static parseFromObject(object: Partial<Customer>): Customer {
        const customer = new Customer();
        Object.assign(customer, object);
        return customer;
    }

    public parseToObject(): Partial<Customer> {
        const tmp = { ...this };
        return tmp;
    }
}
