import Parseable from '@/misc/Parseable';
import Customer from '@/models/Customer';

export default class Request extends Parseable {
    public id!: string;
    public customer!: Customer | string;
    public passengerCount!: number;
    public isShared!: boolean;

    public departure!: string;
    public destination!: string;
    public isClosed!: boolean;
    public isDeleted!: boolean;
    public isPaid!: boolean;
    public createdAt!: Date;

    public static parseFromObject(object: Partial<Request>): Request {
        const request = new Request();
        Object.assign(request, object);

        if (object.customer && (typeof object.customer !== 'string')) {
            request.customer = Customer.parseFromObject(object.customer);
        }

        return request;
    }

    public parseToObject(): Partial<Request> {
        const tmp = { ...this };
        return tmp;
    }
}
