import Parseable from '@/misc/Parseable';
import { UserRoles } from '@/enum/UserRoles';

export default class Manager extends Parseable {
    public role!: UserRoles;
    public login!: string;
    public createdAt!: string;
    public id!: string;
    public status!: boolean;
    public lastLogin!: string;
    public email!: string;

    public static parseFromObject(object: Partial<Manager>): Manager {
        const transaction = new Manager();
        Object.assign(transaction, object);
        return transaction;
    }

    public parseToObject(): Partial<Manager> {
        const tmp = { ...this };
        return tmp;
    }
}
