import Parseable from '@/misc/Parseable';
import Customer from '@/models/Customer';
import DriverTaxi from '@/models/DriverTaxi';
import Offer from '@/models/Offer';
import Payment from '@/models/Payment';

export default class Ride extends Parseable {
    public id!: string;
    public start!: Date;
    public end!: Date;

    public customer!: Customer;
    public driverTaxi!: DriverTaxi;
    public offer!: Offer;
    public payment?: Payment;

    public static parseFromObject(object: Partial<Ride>): Ride {
        const ride = new Ride();
        Object.assign(ride, object);

        if (object.customer) {
            ride.customer = Customer.parseFromObject(object.customer);
        }

        if (object.driverTaxi) {
            ride.driverTaxi = DriverTaxi.parseFromObject(object.driverTaxi);
        }

        if (object.offer) {
            ride.offer = Offer.parseFromObject(object.offer);
        }

        if (object.payment) {
            ride.payment = Payment.parseFromObject(object.payment);
        }

        return ride;
    }

    public parseToObject(): Partial<Ride> {
        const tmp = { ...this };
        return tmp;
    }
}
