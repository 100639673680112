import Parseable from '@/misc/Parseable';
import Driver from '@/models/Driver';
import Manager from '@/models/Manager';
import { PaymentMethod } from '@/enum/PaymentMethod.enum';

export default class Transaction extends Parseable {
    public id!: string;
    public driverId!: string;
    public paymentMethod!: PaymentMethod;
    public driver!: Driver;
    public manager!: Manager;
    public managerId!: string;
    public createdAt!: string;
    public description?: string;
    public amount!: number;

    public static parseFromObject(object: Partial<Transaction>): Transaction {
        const transaction = new Transaction();
        Object.assign(transaction, object);

        return transaction;
    }

    public parseToObject(): Partial<Transaction> {
        const tmp = { ...this };
        return tmp;
    }
}
