import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import i18n from '@/i18n';

/**
 * Converts an ISO date string to a locale date time. The locale date is retrieved from i18n.locale
 */
Vue.filter('toDateTime', (value: string) => {
    if (!value) {
        return '';
    }

    const time = moment(value, moment.ISO_8601, true);
    if (!time.isValid()) {
        return value;
    }

    switch (i18n.locale) {
        case 'en':
        default:
            return time.format('YYYY-MM-DD HH:mm');
    }
});
