import { RideState, RootState, TaxiState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Company from '@/models/Company';
import RideRepository from '@/api/repositories/RideRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Ride from '@/models/Ride';

const rideRepository: RideRepository = RepositoryFactory.get('ride');

function initialRideState(): RideState {
    return {
        rides: []
    };
}

const store: RideState = initialRideState();

export enum rideStoreActions {
    LIST = 'LIST',
    GET = 'GET',
}

export enum rideStoreMutations {
    SAVE_RIDES = 'SAVE_RIDES',
    CLEAR_STORE = 'CLEAR_STORE'
}

/**
 * ACTION SECTION
 */
const actions: ActionTree<RideState, RootState> = {
    [rideStoreActions.LIST]: async ({ commit }, payload: { company: Company }): Promise<Ride[]> => {
        const response = await rideRepository.list(payload.company);
        // Save rides
        const rides = Ride.parseFromArray(response.data) as Ride[];
        commit(rideStoreMutations.SAVE_RIDES, rides);
        return rides;
    },
    [rideStoreActions.GET]: async ({ commit }, payload: { id: string }): Promise<Ride> => {
        const response = await rideRepository.get({ id: payload.id });
        const ride = Ride.parseFromObject(response.data) as Ride;
        return ride;
    }
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<RideState> = {
    [rideStoreMutations.SAVE_RIDES]: (state: RideState, rides: Ride[]) => {
        state.rides = rides;
    },
    [rideStoreMutations.CLEAR_STORE]: (state: RideState) => {
        state.rides = [];
    }
};

/**
 * GETTER SECTION
 */
export enum rideStoreGetter {
    RIDES= 'RIDES'
}

const getters: GetterTree<RideState, RootState> = {
    [rideStoreGetter.RIDES]: (state: RideState) => state.rides
};

const rideStore: Module<RideState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default rideStore;
